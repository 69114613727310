import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import * as serviceWorker from './serviceWorker';

// change page title with window visibility
document.addEventListener('visibilitychange', () => (
  document.hidden
    ? document.title = document.title.replace(' - ', ' | ')
    : document.title = document.title.replace(' | ', ' - ')
));

console.info(`
               ▓▓▓▓▓▓▓▓▓            ▄▄▓▓▓▓▓▓▓▓▓▓▄
               ▓▓▓▓▓▓▓▓▓▓▄      ▄▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
                ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
                 ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
                  ▀▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▀
                     ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▀
                       ▀▀▓▓▓▓▓▓▓▓▓▓▓▓▀─
                              ▀▓▓▓▓▓▓
                               ▓▓▓▓▓▓▄▄▄▄▄▄▄▄_
                               ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
                           ▄▄▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓─
                    ▄▄▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
               ▄▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▀
             ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▀▀▀▀-
            ▓▓▓▓▓▓▓▓▓▓▀▀▀▀▓▓▓▓▓▓▓▓▓▓▓
                             ▓▓▓▓▓▓▓
                          ▓▓▓▓▓▓▓
                         ▓▓▓▓▓▓▓▓▄▄▄▄▄▄▄▄▄▄▄▄
               ▀▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▄_
                ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▄
▄▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓_
  ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▀▀▀▀▀▀▀▀▀▀▀▀▓▓▓▓▓▓▓▓▓▓▓▓▓▓_
     ▀▓▓▓▓▓▓▓▓▓▓▓▀▀▀─-              ▄▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
         ▀▀▀▀▀▀▀▀                        ▓▓▓▓▓▓▓▓▓▓▀
Designed and developed by Andrew Wang.
  `);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
